import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../Constants/enviroment";
import { useNavigate } from "react-router-dom";
const usePost = (endpoint, Body) => {
  const [mes, setMes] = useState("");
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();

  const sendPostRequest = (Body) => {
    setIsLoading("loading");

    axios
      .post(`${config.baseUrl}/${endpoint}`, Body)
      .then((res) => {
        console.log(res);
        setMes("A Build is already running...");

        setTimeout(() => {
          navigate("/logs");
        }, 5000);

        setIsLoading(false);
      })
      .catch((err) => {
        setMes(err.response.data.message);
        setTimeout(() => {
          navigate("/logs");
        }, 5000);
        setIsLoading(true);
      });
  };

  return [isLoading, mes, sendPostRequest];
};

export default usePost;
