import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import "./MyLog.css";
import config from "../../Constants/enviroment";
const MyLog = () => {
  const [logs, setLogs] = useState({});

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${config.baseUrl}/${config.hub}`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.on("receiveLogs", (log) => {
      setLogs((prevLogs) => ({
        ...prevLogs,
        [log.projectName]: [...(prevLogs[log.projectName] || []), log],
      }));

      console.log(log);
      console.log(logs);
    });

    connection
      .start()
      .then(() => console.log("Connection started."))
      .catch((err) => console.error("Error while starting connection.", err));

    return () => {
      connection.stop();
    };
  }, []);

  return (
    <>
      <div>
        {Object.entries(logs).map(([projectName, logs]) => (
          <div className="logs-text" key={projectName}>
            <h2>{projectName}</h2>
            <ul className="log-mess">
              {logs.map((log, index) => (
                <li className="message-li" key={index}>
                  {log.logs}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default MyLog;
