import React, { useState } from 'react'
import "./MyBox.css"
import TimeAgo from '../TimeAgo/TimeAgo';
import usePost from '../../CustomHooks/usePost';
import config from '../../Constants/enviroment';
import { NavLink, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js"


const MyBox = ({ overallStatus, isRunning }) => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [isLoading, mes , sendPostRequest] = usePost(
    config.reCaptcha);
  

  const handleCaptchaVerify = (value) => {
    
    sendPostRequest(
      {
        "environmentName": "dev", "recaptcha":value
      }
    )

    
  };

  const appInsights=useAppInsightsContext();
  const trackExtraLinkClick=useTrackEvent(appInsights , "Exstra Link Click")
  const handleExternalLinkClick=(url) =>{
  trackExtraLinkClick({url});
  }
  const trackStartVerification=useTrackEvent(appInsights , 'Star Verification')
const handleClickk=() =>{
  setShowCaptcha(true);
trackStartVerification()
}

  return (


    <div> {overallStatus === 'Completed' && isRunning ?
      (<div className="my-element">
        <p>StreetThings was deployed  <TimeAgo />  minutes ago by another user and is currently running.
          You can try it out using the following <a href='https://api.deploy.streetthings.abdulrhman.dev'  onClick={() => handleExternalLinkClick("https://dev.streetthings.abdulrhman.dev")}> link.</a>
          Additionally,
          you can view the latest<NavLink to='/logs' onClick={() => handleExternalLinkClick("/logs")} > deployment logs </NavLink>  for the application's infrastructure and services

        </p>

      </div>) : (
        <div>

          {overallStatus === 'InProgress' ?

            (<div className='my-elementt'>

              <p>StreetThings is currently being deployed.The deployment was triggered by another user  <TimeAgo />  minutes ago.
                You can check the current deployment status
                <NavLink to='/logs' onClick={() => handleExternalLinkClick("/logs")} > here. </NavLink>


              </p>
              </div>) :

(<div className='my-elementt'>

  <p>StreetThings is currently decommissioned. Proceeding will provision the infrastructure,
    create a Kubernetes cluster, and deploy the application services</p>
              <div>
              <button onClick={handleClickk}>  Deploy StreetThings</button>
              
      {showCaptcha && (
        <ReCAPTCHA
          sitekey={`${config.sitekey}`}
          onChange={handleCaptchaVerify}
        />
        
      )}
     
     
    
      </div>
              {mes && <div className='err-message'>{mes}</div>}


              <p className='p-text'>StreetThings will be up for one Hour, afterwards the services and infrastructure will be back to the decommisioned state.
                You can check the last build logs infrastructure and services deployment
                <NavLink to='/logs' onClick={() => handleExternalLinkClick("/logs")} > here.</NavLink></p>

            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyBox;
