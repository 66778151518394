import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import './index.css';
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from '@remix-run/router';
const browserHistory=createBrowserHistory({basename:''})
const reactPlugin=new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=a1d7ed60-e44c-4c14-9e44-3f8a403d5453;IngestionEndpoint=https://centralindia-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralindia.livediagnostics.monitor.azure.com/;ApplicationId=454b52a9-8c3b-47e6-8d46-6adc2ae5730a',
        extensions:[reactPlugin],
        extensionConfig:{
            [reactPlugin.identifier]:{history:browserHistory}
        }

    }
});
appInsights.loadAppInsights();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppInsightsContext.Provider value={reactPlugin}>
    <App />
    </AppInsightsContext.Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
