import React from 'react'
import moment from 'react-moment';
const TimeAgo = ({dat}) => {
  return (
    <div>
    {moment(dat).fromNow()}
  </div>
  )
}

export default TimeAgo