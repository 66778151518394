const config = {
    baseUrl: "https://api.deploy.streetThings.abdulrhman.dev",
    env:"api/Builds/last-build",
    envi:"api/Builds/trigger",
    reCaptcha:"api/Builds/trigger/v2",
    logss:"api/Logs",
    hub:"logsHub", 
    sitekey:"6LdYfWEqAAAAACKc2CyhgNTMK-ucIjxfR8diLm3D"
  };
  export default config;
  